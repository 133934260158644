<template>
  <v-container fluid>
    <v-data-table :headers="headers"
                  :items=overViewData
                  :multi-sort="false"
                  :search="search"
                  class="elevation-1">
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.icons="props">
        <v-icon v-if="props.item.isSecondary" color="orange">
          {{ 'mdi-database-plus' }}
        </v-icon>
        <v-icon v-else :color="themeColor">
          {{ 'mdi-database' }}
        </v-icon>
      </template>
      <template v-slot:item.name="props">
        <span v-if="props.item.active" color="green">{{ props.item.name }}</span>
        <span v-else font-style="italic;color: orange;">{{ props.item.name }}</span>
      </template>
    </v-data-table>
  </v-container>

</template>

<script>
import {getConfigObject} from "../service/Common";

export default {
  name: "StorageSystemOverView",
  props: ['themeColor', 'configData'],
  data: () => ({
    headers: [
      {
        text: '',
        align: 'left',
        sortable: true,
        value: 'icons',
        width: '90px'
      },
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Active',
        align: 'left',
        sortable: true,
        value: 'active',
      },
      {
        text: 'Shard Size',
        align: 'left',
        sortable: true,
        value: 'blockSize',
      },
      {
        text: 'System',
        align: 'left',
        sortable: true,
        value: 'ShardFileSystemName'
      }],
      search: '',
      pagination: {
        rowsPerPage: 10
      },
      overViewData: []
  }),
  methods: {
    initLoad(){
      console.log("Init")
      console.log(this.configData)
      if( this.configData != null &&  this.configData["ShardFileSystems"] != null ){
        var indexNr = 0
        console.log("Init2")
        for (var item of this.configData["ShardFileSystems"]) {
          console.log("Init3")
          for( var shardFile of item.Shardfiles){
            var shardfileTmp = JSON.parse(JSON.stringify(shardFile));
            //console.log(shardfileTmp)
            var secondaryStorageName = ''
            var primaryStorageName = ''
            if( shardfileTmp.secondaryStorageId >= 0  ){
              secondaryStorageName = getConfigObject(shardfileTmp.secondaryStorageId, this.configData).name
            }
            if( shardfileTmp.primaryStorageId >= 0  ){
              primaryStorageName = getConfigObject(shardfileTmp.primaryStorageId, this.configData).name
            }
            shardfileTmp.secondaryStorageName = secondaryStorageName
            shardfileTmp.primaryStorageName = primaryStorageName

            shardfileTmp.index = indexNr
            shardfileTmp.ShardFileSystemUID = item.uid
            shardfileTmp.ShardFileSystemName = item.name
            this.overViewData.push(shardfileTmp)
            indexNr++
          }
        }
      }
    }
  },
  mounted() {
    this.initLoad()
  }
}
</script>

<style scoped>

</style>