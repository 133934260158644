<template>
  <v-container>
    <v-card
        class="pt-6 mx-auto"
        flat

    >
      <v-card-text>

        <h3 class="headline mb-2" :title="uid">
          {{ name }}
        </h3>

      </v-card-text>
      <v-divider></v-divider>
      <v-switch
          v-model="active"
          name="active"
          label="active"
          @change="changedType(active, 'active')"
      ></v-switch>
      <v-text-field v-model="name" label="Name" name="name" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-model="description" label="Description" name="description"
                    @input.native="changedAttribute"></v-text-field>
      <v-text-field v-model="order" label="Order" sm="12" name="order" type="number"
                    @input.native="changedAttribute" min="1"></v-text-field>
      <div align="left" class="red--text ">{{ failOrderText }}</div>
      <v-divider></v-divider>
      <div align="left">
        <v-btn small :color="themeColor" class="white--text" right @click="editRule">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        Rule: {{ ruleText }}
      </div>
      <div align="left">
        <v-btn small :color="themeColor" class="white--text" right @click="openEditShardContainerFilesDialog">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        Shard Container Files:
      </div>
      <v-list>
        <v-list-item v-for="(shardContainerFile,index) in shardContainerFilesDisplay" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ shardContainerFile.name }}
              <v-icon v-if="shardContainerFile.secondaryDisplayName.length > 0" color="green">mdi-arrow-right-bold
              </v-icon>
              <span v-if="shardContainerFile.secondaryDisplayName.length > 0">{{
                  shardContainerFile.secondaryDisplayName
                }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-switch
          v-model="shuffle"
          name="shuffle"
          label="Shuffle"
          @change="changedType(shuffle, 'shuffle')"
      ></v-switch>
      <v-divider></v-divider>
      <v-switch
          v-model="compression"
          name="compression"
          label="Compression"
          @change="changedType(compression, 'compression')"
      ></v-switch>
      <v-select
          :items="compressionAlgoItems"
          v-model="compressionAlgo"
          name="compressionAlgo"
          @change="changedType(compressionAlgo, 'compressionAlgo')"
          label="Compression Algorithm"
          item-value="id"
          item-text="name"
      ></v-select>
      <v-switch
          v-model="digest"
          name="digest"
          label="Digest"
          @change="changedType(digest, 'digest')"
      ></v-switch>
      <v-divider></v-divider>
      <v-text-field v-model="poisonPercentage" label="Poison Percentage" name="poisonPercentage" type="number" min="0"
                    max="99"
                    step=".1" @input.native="changedAttribute"></v-text-field>
      <v-select
          :items="poisonTypeItems"
          v-model="poisonMix"
          name="poisonMix"
          @change="changedType(poisonMix, 'poisonMix')"
          label="Poison Mix"
          item-value="id"
          type="number"
          item-text="name"
      ></v-select>
      <v-switch
          :disabled="policyItem.shardContainerFiles == null || policyItem.shardContainerFiles != null && policyItem.shardContainerFiles.length < 2"
          v-model="selfHealing"
          name="selfHealing"
          :label="policyItem.shardContainerFiles != null && policyItem.shardContainerFiles.length < 2 ? 'Self healing (requires minimum 2 Shard container files)' : 'Self healing'"
          @change="changedType(selfHealing, 'selfHealing')"
      ></v-switch>
      <v-alert v-if="selfHealing"
               outlined
               type="warning"
               border="top"
      >
        For self healing to work properly, make sure that no more than 1 container is hosted on each configured
        filesystem.
      </v-alert>
      <v-alert
          v-if="selfHealing"
          border="top"
          type="info"
          outlined
      >
        1 container will be designated for self healing functionality.
      </v-alert>
      <v-alert v-if="mixedPrimarySecondaryShardContainerfiles"
               outlined
               type="warning"
               border="top"
      >
        The policy have a mix of buckets with and without secondary storage. This is not recomended.
      </v-alert>
      <v-btn small :color="themeColor" class="white--text" @click="deletePolicyDialog = true">Delete</v-btn>
    </v-card>
    <v-dialog v-model="editRuleDialog"
              hide-overlay
              persistent
              scrollable
              width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit rule
        </v-card-title>
        <v-card-text>
          <v-select
              :items="ruleTypeItems"
              v-model="ruleType"
              label="Rule Type"
              item-value="id"
              item-text="name"
              size="4"
              @change="changedRuleType(ruleType, 'ruleType')"
          ></v-select>
          <v-select
              v-if="ruleType == 'meta_data'"
              :items="ruleMetaDataTypeItems"
              v-model="ruleMetaDataType"
              label="Metadata Type"
              item-value="id"
              item-text="name"
              size="4"
              @change="changedRuleMetaDataType(ruleMetaDataType, 'ruleMetaDataType')"
          ></v-select>

          <v-text-field v-if="ruleType == 'meta_data'" v-model="ruleMetdataName" :label="ruleMetdataNameLabel" sm="12" name="ruleMetdataName" type="ruleMetdataName"></v-text-field>
          <v-select
              :items="ruleOperatorItems"
              v-model="ruleOperator"
              label="Rule Operator"
              item-value="id"
              item-text="name"
              size="4"
          ></v-select>
          <v-text-field v-model="ruleValue" label="Value" sm="12" name="ruleValue" type="ruleValue"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn :color="themeColor" class="white--text" text @click="editRuleDialog = false">Cancel</v-btn>
          <v-btn :color="themeColor" class="white--text" text @click="saveRule">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editShardContainerFilesDialog"
              hide-overlay
              persistent
              scrollable
              width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Shard Container Files
        </v-card-title>
        <v-card-text>
          Configured Shard Container Files
          <v-list>
            <v-list-item v-for="(shardContainerFile,index) in shardContainerFilesDisplay" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ shardContainerFile.name }}
                  <v-icon v-if="shardContainerFile.secondaryDisplayName.length > 0" color="green">mdi-arrow-right-bold
                  </v-icon>
                  <span v-if="shardContainerFile.secondaryDisplayName.length > 0">{{
                      shardContainerFile.secondaryDisplayName
                    }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action @click="removeShardContainerFile(index)">
                <v-icon :color="themeColor">mdi-delete-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          Available Shard Container Files
          <v-list>
            <v-list-item v-for="(shardContainerFile,index) in shardContainerFilesNotUsedInPolicy" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ shardContainerFile.container.name }}
                  <v-icon v-if="shardContainerFile.container.secondaryDisplayName.length > 0" color="green">
                    mdi-arrow-right-bold
                  </v-icon>
                  <span v-if="shardContainerFile.container.secondaryDisplayName.length > 0">{{
                      shardContainerFile.container.secondaryDisplayName
                    }}</span></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action @click="addShardContainerFile(shardContainerFile.uid)">
                <v-icon :color="themeColor">mdi-plus-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-alert v-if="mixedPrimarySecondaryShardContainerfiles"
                   outlined
                   type="warning"
                   border="top"
          >
            The policy have a mix of buckets with and without secondary storage. This is not recomended.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn :color="themeColor" class="white--text" text @click="editShardContainerFilesDialog = false">Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePolicyDialog"
              hide-overlay
              persistent
              scrollable
              width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Delete
        </v-card-title>
        <v-card-text>Are you sure you want to delete policy {{ name }}?</v-card-text>
        <v-card-actions>
          <v-btn :color="themeColor" class="white--text" text @click="deletePolicyDialog = false">Cancel</v-btn>
          <v-btn :color="themeColor" class="white--text" text @click="deletePolicy">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  EventBus
} from '../Events.js';
import {getConfigObject} from "../service/Common";

export default {
  name: "Policy",
  props: ['policyItem', 'policyOrders', 'availableShardContainerFiles', 'themeColor', 'configData'],
  data: () => ({
    selfHealing: true,
    uid: "",
    active: true,
    name: "",
    order: "",
    description: "",
    failOrderText: "",
    rule: "",
    shardContainerFiles: [],
    shardContainerFilesDisplay: [],
    shardContainerFilesNotUsedInPolicy: [],
    compression: true,
    compressionAlgo: "lz4",
    compressionAlgoItems: [{id: "lz4", name: "LZ4"}, {id: "gzip", name: "GZIP"}],
    digest: false,
    poisonPercentage: "",
    poisonMix: "",
    poisonTypeItems: [{id: 0, name: 'Binary'}, {id: 1, name: 'Text'}, {id: 2, name: 'Auto based on data'}, {
      id: 3,
      name: 'Shuffling rawdata'
    }],
    ruleText: "",
    ruleType: "",
    ruleMetdataName: "",
    ruleMetdataNameLabel: "",
    ruleMetaDataType: "",
    ruleOperator: "",
    ruleValue: "",
    ruleTypeItems: [{id: "name", name: "Name"}, {id: "size", name: "Size"}, {id: "meta_data", name: "Metadata"}],
    ruleMetaDataTypeItems: [{id: "x-amz-tag-", name: "S3 Tag"}, {id: "x-amz-meta-", name: "S3 Header"}],
    ruleOperatorItems: [],
    ruleOperatorItemsSize: [{id: "<", name: "Less Than"}, {id: ">", name: "Greater Than"}, {id: "=", name: "Equal "}],
    ruleOperatorItemsName: [{id: "starts", name: "Starts With"},{id: "ends", name: "Ends With"},{id: "contains", name: "Contains"}, {
      id: "equals",
      name: "Equals"
    }],
    ruleOperatorItemsMetadata: [{id: "equals",name: "Equals (String) "},{id: "starts", name: "Starts With (String)"}, {id: "contains", name: "Contains (String)"}, {id: "=",name: "Equals (Numerical) "},{id: "<", name: "Less Than (Numerical)"}, {id: ">", name: "Greater Than (Numerical)"}],
    editRuleDialog: false,
    editShardContainerFilesDialog: false,
    deletePolicyDialog: false,
    shuffle: true,
    mixedPrimarySecondaryShardContainerfiles: false
  }),
  methods: {
    changedAttribute(a) {
      var result = []
      if (a.target.name == "poisonPercentage") {
        this.policyItem[a.target.name] = parseFloat(this[a.target.name]);
        result[a.target.name] = parseFloat(this[a.target.name]);
      } else if (a.target.type == "number") {
        this.policyItem[a.target.name] = parseInt(this[a.target.name]);
        result[a.target.name] = parseInt(this[a.target.name])
      } else {
        this.policyItem[a.target.name] = this[a.target.name];
        result[a.target.name] = this[a.target.name];
      }
      if (a.target.name == "order") {
        this.failOrderText = "";
        for (var item in this.policyOrders) {
          if (this.policyOrders[item]["order"] == this[a.target.name] && item != this.uid) {
            this.failOrderText = "Note: This is same order as '" + this.policyOrders[item]["name"] + "'";
          }
        }
        if( this[a.target.name] == "" ){
          this.order = this.getOrderFromConfig()
          EventBus.$emit('changedPolicyOrder', true)
          return
        }
        EventBus.$emit('changedPolicyOrder', true)
      }
      return;

    },
    getOrderFromConfig(){
      var highestValue = 0;
      for (var policy in this.policyOrders) {
        if(policy != this.uid && this.policyOrders[policy]["order"] > highestValue){
          highestValue = this.policyOrders[policy]["order"]
        }
      }
      highestValue++
      return highestValue
    },
    changedType(type, inputObject) {
      this.policyItem[inputObject] = type;
    },
    editRule() {
      var ruleData = this.getRuleSplitted()
      if (ruleData != null) {
        if( ruleData[0].startsWith('meta_data')){
          this.ruleType = ruleData[0].substr(0, ruleData[0].indexOf(":"));
          if(ruleData[0].startsWith("meta_data:x-amz-tag-") ){
            this.ruleMetaDataType = "x-amz-tag-"
            this.ruleMetdataName = ruleData[0].substr("meta_data:x-amz-tag-".length)
            this.ruleMetdataNameLabel = "Metadata S3 tag name"
          }else{
            this.ruleMetaDataType = "x-amz-meta-"
            this.ruleMetdataName = ruleData[0].substr("meta_data:x-amz-meta-".length)
            this.ruleMetdataNameLabel = "Metadata S3 header name"
          }
          this.ruleOperatorItems = this.ruleOperatorItemsMetadata;
        }else{
          this.ruleType = ruleData[0]
        }
        if (this.ruleType == "size") {
          this.ruleOperatorItems = this.ruleOperatorItemsSize;
        }
        this.ruleOperator = ruleData[1]
        this.ruleValue = ruleData[2]
      }
      this.editRuleDialog = true
    },
    saveRule() {
      if( this.ruleType == 'meta_data' ){
        this.rule = this.ruleType + ":" + this.ruleMetaDataType  + this.ruleMetdataName + "|" + this.ruleOperator + "|" + this.ruleValue
      }else{
        this.rule = this.ruleType + "|" + this.ruleOperator + "|" + this.ruleValue
      }
      this.policyItem.rule = this.rule
      this.ruleText = this.getRuleText()
      this.editRuleDialog = false;
    },
    getRuleSplitted() {
      if (this.rule != null && this.rule.length > 0) {
        return this.rule.split("|");
      } else {
        return null;
      }
    },
    getRuleText() {
      var currRule = this.getRuleSplitted()
      if (currRule != null) {
        var ruleRead = ""
        if( currRule[0].startsWith("meta_data" )){
          if(currRule[0].startsWith("meta_data:x-amz-tag-") ){
            ruleRead = "Metadata S3 tag with name " + currRule[0].substr("meta_data:x-amz-tag-".length) + " "
          }else{
            ruleRead = "Metadata S3 header with name " + currRule[0].substr("meta_data:x-amz-meta-".length) + " "
          }

        }else{
          ruleRead = currRule[0] + " ";
        }
        if (currRule[0] == "size" || currRule[0].startsWith("meta_data")) {
          if (currRule[1] == ">") {
            ruleRead = ruleRead + "is greater than ";
          } else if (currRule[1] == "<") {
            ruleRead = ruleRead + "is less than ";
          } else if (currRule[1] == "=" ) {
            ruleRead = ruleRead + "equals ";
          }else if (currRule[1] == "starts" ) {
            ruleRead = ruleRead + "starts with ";
          }else{
            ruleRead = ruleRead + currRule[1] + " ";
          }
        } else if (currRule[1] == "starts" ) {
          ruleRead = ruleRead + " starts with ";
        }else if (currRule[1] == "ends" ) {
          ruleRead = ruleRead + "ends with ";
        }else {
          ruleRead = ruleRead + currRule[1] + " ";
        }
        ruleRead = ruleRead + currRule[2];
        return ruleRead;
      }
      return "No rule set"
    },
    changedRuleType(type) {
      //@change="changedType(poisonmix, 'poisonmix')"
      if (type == "size") {
        this.ruleOperatorItems = this.ruleOperatorItemsSize;
      }else if (type == "meta_data") {
        this.ruleOperatorItems = this.ruleOperatorItemsMetadata;
      } else {
        this.ruleOperatorItems = this.ruleOperatorItemsName;
      }
    },
    changedRuleMetaDataType(type){
      if (type == "x-amz-tag-") {
        this.ruleMetdataNameLabel = "Metadata S3 tag name"
      } else {
        this.ruleMetdataNameLabel = "Metadata S3 header name"
      }
    },
    getShardContainerFilesText() {
      var result = [];
      for (var x = 0; x < this.shardContainerFiles.length; x++) {
        if (this.availableShardContainerFiles[this.shardContainerFiles[x]] != null) {
          result.push(this.availableShardContainerFiles[this.shardContainerFiles[x]]);
        } else {
          result.push("Missing Shard Container File in config '" + this.shardContainerFiles[x] + "'");
        }
      }
      return result;

    },
    deletePolicy() {
      EventBus.$emit('deletePolicyID', this.uid)
    },
    openEditShardContainerFilesDialog() {
      //for( var x=0; x < this.shardContainerFilesNotUsedInPolicy.length; x++){
      this.updateAvailableShardContainerFiles();
      this.informationMessageShardContainerfiles = ""
      this.editShardContainerFilesDialog = true;
    },
    updateAvailableShardContainerFiles() {
      this.shardContainerFilesNotUsedInPolicy = []
      for (var uidOfSCF in this.availableShardContainerFiles) {
        if (!this.shardContainerFiles.includes(parseInt(uidOfSCF))) {
          this.shardContainerFilesNotUsedInPolicy.push({
            container: this.availableShardContainerFiles[uidOfSCF],
            uid: uidOfSCF
          });
        }
      }
    },
    removeShardContainerFile(index) {
      this.policyItem.shardContainerFiles.splice(index, 1);
      this.shardContainerFilesDisplay = this.getShardContainerFilesText();
      this.updateAvailableShardContainerFiles();

      this.selfHealing = this.policyItem.shardContainerFiles.length > 1
      this.changedType(this.selfHealing, 'selfHealing')
      this.checkShardcontainerFilesConfig()
    },
    addShardContainerFile(index) {
      if (this.policyItem.shardContainerFiles == null) {
        this.policyItem.shardContainerFiles = []
        this.shardContainerFiles = this.policyItem.shardContainerFiles
      }
      this.policyItem.shardContainerFiles.push(parseInt(index));
      this.shardContainerFilesDisplay = this.getShardContainerFilesText();
      this.updateAvailableShardContainerFiles();

      this.selfHealing = this.policyItem.shardContainerFiles.length > 1
      this.changedType(this.selfHealing, 'selfHealing')
      this.checkShardcontainerFilesConfig()
    },
    checkShardcontainerFilesConfig() {
      this.mixedPrimarySecondaryShardContainerfiles = false
      var hasPrimaryBuckets = false
      var hasNonPrimaryBuckets = false
      for (var x = 0; x < this.shardContainerFiles.length; x++) {
        var uidOfSCF = this.shardContainerFiles[x]
        if (getConfigObject(uidOfSCF, this.configData).primaryStorageId != null && getConfigObject(uidOfSCF, this.configData).primaryStorageId >= 0) {
          hasPrimaryBuckets = true
        }
        if (getConfigObject(uidOfSCF, this.configData).secondaryStorageId != null && getConfigObject(uidOfSCF, this.configData).secondaryStorageId >= 0) {
          hasNonPrimaryBuckets = true
        }
      }
      if (hasPrimaryBuckets != hasNonPrimaryBuckets) {
        this.mixedPrimarySecondaryShardContainerfiles = true
      }

    }
  },
  mounted() {
    if (this.policyItem.shardContainerFiles == null || this.policyItem.shardContainerFiles.length < 2) {
      this.selfHealing = false
    }
    for (var item in this.policyItem) {
      if (this[item] != null) {
        this[item] = this.policyItem[item];
      }
    }
    if (this.policyItem.selfHealing == null) {
      this.changedType(this.selfHealing, 'selfHealing')
    } else {
      this.changedType(this.policyItem.selfHealing, 'selfHealing')
    }
    this.ruleText = this.getRuleText()
    this.shardContainerFilesDisplay = this.getShardContainerFilesText();
    if (this.rule.startsWith("size")) {
      this.ruleOperatorItems = this.ruleOperatorItemsSize;
    } else if (this.rule.startsWith("meta_data")) {

      this.ruleOperatorItems = this.ruleOperatorItemsMetadata;
    } else {
      this.ruleOperatorItems = this.ruleOperatorItemsName;
    }
    this.checkShardcontainerFilesConfig()
  },
  beforeDestroy() {
    //EventBus.$off("changedPolicyOrder")

  }

}
</script>

<style scoped>

</style>