import { render, staticRenderFns } from "./PrepareConfig.vue?vue&type=template&id=4f23783e&scoped=true&"
import script from "./PrepareConfig.vue?vue&type=script&lang=js&"
export * from "./PrepareConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f23783e",
  null
  
)

export default component.exports