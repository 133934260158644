<template>
  <v-container>
    <div>
      <table>
        <tr>
          <td style="width:500px">
            <v-file-input accept=".json" label="Select config file" v-model="configFile" @change="loadTextFromFile"></v-file-input>
          </td>
          <td>
            <v-btn small :color="themeColor" class="white--text" @click="saveConfigToFile">Save Config</v-btn>
          </td>
        </tr>
      </table>
      <v-divider light></v-divider>
      <v-card min-height="600px">
        <v-card-title class="light-blue darken-4 white--text headline">
          Configuration
        </v-card-title>
        <v-row
            class="pa-4"
            justify="space-between"
        >
          <v-col>
            <v-treeview
                :active.sync="activeInTree"
                :items="items"
                :open.sync="open"
                item-key="uid"
                @update:active="openObject"
                activatable
                color="warning"
                transition

            >
              <template v-slot:prepend="{ item }">
                <v-icon :color="getIcon(item.uid).color">{{ getIcon(item.uid).icon }}</v-icon>
              </template>
            </v-treeview>

          </v-col>
          <v-divider vertical></v-divider>
          <v-col
              class="d-flex text-center"
          >
            <v-scroll-y-transition mode="out-in">


              <v-card v-if="showStart"
                      :key="uid"
                      flat
              >

                <v-card-text>

                  <h3 class="headline mb-2">
                    ShardSecure config
                  </h3>

                </v-card-text>
                <v-divider></v-divider>
                <v-row style="height: 80px;"
                       class="text-left"
                       tag="v-card-text"
                >
                  <v-col class="d-flex" sm="20">
                    <v-text-field v-model="description" name="description" @input.native="changedAttribute"
                                  label="Description"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 80px;"
                       class="text-left"
                       tag="v-card-text"
                >
                  <v-col class="d-flex" sm="20">
                    <v-text-field v-model="created" name="created" @input.native="changedAttribute"
                                  label="Created"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 80px;"
                       class="text-left"
                       tag="v-card-text"
                >
                  <v-col class="d-flex" sm="20">
                    <v-text-field v-model="lastEdit" name="lastEdit" @input.native="changedAttribute"
                                  label="Last Edit"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 80px;"
                       class="text-left"
                       tag="v-card-text"
                >
                  <v-col class="d-flex" sm="20">
                    <v-text-field v-model="editBy" name="editBy" @input.native="changedAttribute"
                                  label="Edited By"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 80px;"
                       class="text-left"
                       tag="v-card-text"
                >
                  <v-col class="d-flex" sm="20">
                    <v-text-field v-model="version" name="version" @input.native="changedAttribute"
                                  label="Version"></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <!-- General tab -->
              <v-card v-else-if="showGeneral"
                      :key="uid"
                      class="pt-6 mx-auto"
                      flat
              >
                <v-card-text>

                  <h3 class="headline mb-2">
                    General Config
                  </h3>

                </v-card-text>
                <v-divider></v-divider>
                <v-row style="height: 80px; width: 500px"
                       class="text-left"
                       tag="v-card-text"
                >
                  <v-col class="d-flex" sm="20">
                    <v-text-field v-model="LocalTempDirectoryForMove" name="LocalTempDirectoryForMove"
                                  @input.native="changedAttribute" label="LocalTempDirectoryForMove"
                                  size="4"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 80px; width: 500px"
                       class="text-left"
                       tag="v-card-text"
                >
                  <v-col class="d-flex" sm="20">
                    <v-text-field v-model="ShardWriteBufferPoolPrefillCount" name="ShardWriteBufferPoolPrefillCount"
                                  @input.native="changedAttribute" label="ShardWriteBufferPoolPrefillCount"
                                  type="number" min="10" max="999"></v-text-field>
                  </v-col>
                </v-row>
                <br/>
                <v-row>
                  <v-col style="display: flex; justify-content: center; align-items: center"
                         :cols="autoSelfHealingDisableSwitch ? 3 : 4">
                    <div>Auto self healing</div>
                  </v-col>
                  <v-col>
                    <v-switch v-model="autoSelfHealingEnabled" :disabled="autoSelfHealingDisableSwitch" @change="manuallyChangedAutoSelfHealing"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert
                        v-if="autoSelfHealingDisableSwitch"
                        border="top"
                        type="info"
                        outlined
                    >
                      Auto self healing can only be enabled if self healing has been enabled in at least one policy.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card>
              <!-- END General tab -->
              <!-- SFS tab -->
              <v-card v-else-if="showSFSDefault"
                      :key="uid"
                      class="pt-6 mx-auto"
                      flat
              >
                <v-btn
                    absolute
                    dark
                    fab
                    top
                    right
                    x-small
                    class="white--text"
                    :color="themeColor"
                    @click="addStorageSystemDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <StorageSystemOverView :themeColor="themeColor"
                                       :configData="cfgData"></StorageSystemOverView>
              </v-card>
              <ShardFileSystem v-else-if="showSFS" :shardFileSystemItem="shardFileSystemItem" :themeColor="themeColor"
                               :key="displayKey"/>
              <v-card v-else-if="showAddSCF"
                      :key="uid"
                      class="pt-6 mx-auto"
                      flat
              >
                <v-card-text>

                  <h3 class="headline mb-2">
                    Add new SF
                  </h3>

                </v-card-text>
                <v-divider></v-divider>

                <v-text-field v-model="name" label="Name" name="name"></v-text-field>
                <v-text-field v-model="fullFilePath" :label="pathLabel" name="fullFilePath" ></v-text-field>
                <div style="text-align: left;font-style: italic;" v-if="showSMBPath">\\{{ smbShare }}\{{ fullFilePath }}</div>
                <v-text-field v-model="blockSize" label="Shard Size" name="blockSize" type="number"></v-text-field>
                <v-btn small :color="themeColor" class="white--text" @click="addSCF">Add SCF</v-btn>
              </v-card>
              <!-- END SFS tab -->
              <!-- SF tab -->
              <ShardContainerFile v-else-if="showSFC" :shardContainerFileItem="shardContainerFileItem"
                                  :shardContainerFileItemType="shardContainerFileItemType" :themeColor="themeColor"
                                  :shardFileSystems="cfgData['ShardFileSystems']"
                                  :key="displayKey"/>
              <!-- END SF tab -->
              <!-- Poicy tab -->
              <v-card v-else-if="showPolicyDefault"
                      :key="uid"
                      class="pt-6 mx-auto"
                      flat
              >
                <v-btn
                    absolute
                    dark
                    fab
                    top
                    right
                    x-small
                    class="white--text"
                    :color="themeColor"
                    @click="addPolicyDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-card-text>
                  <h3 class="headline mb-2">
                    Policies
                  </h3>
                </v-card-text>

                <ConfigOverview  :themeColor="themeColor"
                                 :configData="cfgData"></ConfigOverview>
              </v-card>
              <Policy v-else-if="showPolicy" :policyItem="policyItem" :policyOrders="policyOrders"
                      :availableShardContainerFiles="availableShardContainerFiles" :themeColor="themeColor"
                      :key="displayKey" :configData="cfgData"></Policy>
              <!-- END Policy tab -->
              <!-- Overview tab -->

              <!-- RAW tab -->
              <v-card v-else-if="showRaw"
                      :key="uid"
                      class="pt-6 mx-auto"
                      flat
                      max-width="900"
                      min-width="700"
                      min-height="800"
              >
                <v-card-text>

                  <h3 class="headline mb-2">
                    RAW
                  </h3>

                </v-card-text>
                <v-divider></v-divider>
                <v-textarea name="raw_edit" row-height="40" auto-grow v-model="textData"></v-textarea>

              </v-card>
              <!-- END RAW tab -->
              <!-- Prepare config tab -->
              <PrepareConfig v-else-if="showPrepareConfig" :configData="cfgData" :themeColor="themeColor"
                             :key="displayKey"></PrepareConfig>
              <!-- END Prepare Config Tab -->
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-card>

      <v-dialog v-model="addPolicyDialog"
                hide-overlay
                persistent
                scrollable
                width="400">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Add Policy
          </v-card-title>
          <v-card-text><v-text-field v-model="name" label="Name"></v-text-field></v-card-text>
          <v-card-actions>
            <v-btn :color="themeColor" class="white--text" text @click="addPolicyDialog = false">Cancel</v-btn>
            <v-btn small :color="themeColor" class="white--text" @click="addPolicy" :disabled="name.length === 0">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addStorageSystemDialog"
                hide-overlay
                persistent
                scrollable
                width="400">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Add Storage System
          </v-card-title>
          <v-card-text>
            <v-overflow-btn
                :items="dropdown_add_sfs"
                label="Select Shard File System Type"
                target="#dropdown-example"
                v-model="selectedSFSToAdd"
            ></v-overflow-btn>
            <v-text-field v-model="name" label="Name"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn :color="themeColor" class="white--text" text @click="addStorageSystemDialog = false">Cancel</v-btn>
            <v-btn small :color="themeColor" class="white--text" @click="addStorageSystem" :disabled="name.length === 0">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor" multi-line>
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
//import cfgData from "./config.env"
//import configData from './cfgData' //JTO remove
import Policy from "./Policy"
import ShardContainerFile from "./ShardContainerFile"
import ShardFileSystem from "./ShardFileSystem"
import PrepareConfig from "./PrepareConfig"
import ConfigOverview from "./PolicyOverview"
//import SvgIcon from "@/components/utils/icons/SvgIcon";
import {
  EventBus
} from '../Events.js';
import {getConfigObject} from "../service/Common";
import StorageSystemOverView from "@/components/StorageSystemOverView";

export default {

  components: {
    StorageSystemOverView,
    ShardFileSystem,
    Policy,
    ShardContainerFile,
    PrepareConfig,
    ConfigOverview

  },
  props: ['themeColor'],
  data: () => ({
    autoSelfHealingDisableSwitch: true,
    autoSelfHealingEnabled: false,
    cfgData: {
      "ShardFileSystems": [],
      "General": {},
      "Policies": []
    },
    displayKey: 0,
    textData: "",
    version: "1.0",
    infoAttributes: ["description", "created", "lastEdit", "editBy", "version"],
    //General attributes
    LocalTempDirectoryForMove: "", //Path, default system tmp
    ShardWriteBufferPoolPrefillCount: "",
    generalAttributes: [
      "LocalTempDirectoryForMove",
      "ShardWriteBufferPoolPrefillCount"
    ],
    //Shared attributes
    uid: "",
    name: "",
    fullFilePath: "",
    blockSize: 4,
    pathLabel: "Bucket",
    description: "",
    created: "",
    lastEdit: "",
    editBy: "",
    //SFS attributes
    shardFileSystemItem: [],
    //SF attributes
    shardContainerFileItem: [],
    shardContainerFileItemType: '',
    //Policy attributes
    policyItem: [],
    policyOrders: [],
    availableShardContainerFiles: [],
    availableSecondaryShardContainerFiles: [],
    sfType: "",
    selectedSFSToAdd: "",
    showStart: true,
    //showAws: false,
    activeInTree: [],
    open: [],
    isAWS: false,
    isGoogle: false,
    isBackblaze: false,
    isWasabi: false,
    isDigitalOcean: false,
    isSMB: false,
    isOracle: false,
    isMS: false,
    isIBM: false,
    isLocal: false,
    showSFS: false,
    showSFSDefault: false,
    showSFC: false,
    showGeneral: false,
    showPolicy: false,
    showPolicyDefault: true,
    showAddSFS: false,
    showAddSCF: false,
    showSMBPath: false,
    smbShare: "",
    showOverView: false,
    showRaw: false,
    showPrepareConfig: false,
    //Tree view,
    ShardFileSystemsPosition: -1,
    PoliciesPosition: -1,
    items: [
      {
        uid: 1010,
        name: 'Start'
      },
      {
        uid: 10000,
        name: 'General config'
      },
      {
        uid: 10001,
        name: 'ShardFileSystems',
        children: [],
      },
      {
        uid: 10002,
        name: 'Policies',
        children: [],
      },
      {
        uid: 10006,
        name: 'Raw'
      },
      {
        uid: 10007,
        name: 'Prepare config'
      }

    ],
    configFile: null,
    policyItems: [],
    dropdown_add_sfs: ['AWS', 'Google', 'Backblaze', 'Wasabi', 'DigitalOcean', 'IBM', 'Microsoft', 'SMB', 'Oracle', 'Generic S3', 'ALICloud', 'Local disk', 'Dropbox', 'Box'],
    snack: false,
    snackColor: '',
    snackText: '',
    manuallyDisabledAutoSelfHealing: false,
    addPolicyDialog: false,
    addStorageSystemDialog: false
  }),
  watch: {
    autoSelfHealingEnabled: function (newVal) {
      this.cfgData["General"].AutomaticSelfHealing = newVal
    }
  },
  methods: {
    manuallyChangedAutoSelfHealing: function (){

      this.manuallyDisabledAutoSelfHealing = !this.autoSelfHealingEnabled
    },
    loadTextFromFile() {
      this.showStart = true
      this.name = "";
      this.activeInTree[1010]
      this.items[this.PoliciesPosition]['children'] = []
      this.items[this.ShardFileSystemsPosition]['children'] = []
      this.cfgData = {
        "ShardFileSystems": [],
        "General": {},
        "Policies": []
      };
      this.shardFileSystemItem = [];
      this.shardContainerFileItem = [];
      this.policyItem = [];
      this.policyOrders = [];
      this.availableShardContainerFiles = [];
      this.activeInTree = [1010];
      this.open = [];
      if (!this.configFile) {
        return;
      }
      var reader = new FileReader();

      reader.readAsText(this.configFile);
      reader.onload = () => {
        this.loadConfig(reader.result)
      }
    },
    loadOnStart() {
      this.activeInTree = [1010];
      for (var x = 0; x < this.items.length; x++) {
        if (this.items[x].name == 'Policies') {
          this.PoliciesPosition = x;
        } else if (this.items[x].name == 'ShardFileSystems') {
          this.ShardFileSystemsPosition = x;
        }
      }
      this.items[this.PoliciesPosition]['children'] = this.cfgData["Policies"];
    },
    loadConfigObj(configData) {
      //Read info attributes
      for (var infoAttrib of this.infoAttributes) {
        this[infoAttrib] = configData[infoAttrib];
      }

      //Read general attributes
      if (configData["General"] != null) {
        for (var attrib of this.generalAttributes) {
          this[attrib] = configData["General"][attrib];
        }
      }

      for (var sfsItem of configData["ShardFileSystems"]) {
        if (sfsItem != null) {
          //Need to get children
          var children = this.getChildrenForSFS(sfsItem["uid"]);
          this.items[this.ShardFileSystemsPosition]['children'].push({
            uid: sfsItem["uid"],
            name: sfsItem["name"],
            children: children
          });
        }
      }
      this.items[this.PoliciesPosition]['children'] = configData["Policies"]
          .sort((a, b) => a.order - b.order)
    },
    isBoolean(dataIn) {
      if (typeof dataIn === "boolean") {
        return true
      }
      return false;
    },
    loadConfig(configData) {
      //this.text = configData
      //Get into json

      try {
        let obj = JSON.parse(configData)
        obj["Policies"] = obj["Policies"].map(obj => ({
          ...obj,
          selfHealing: obj.selfHealing != null ? obj.selfHealing : false
        }))
        obj["Policies"].forEach(x => {
          if ( x.shardContainerFiles != null && x.shardContainerFiles.length < 2) {
            x.selfHealing = false
            this.autoSelfHealingDisableSwitch = true
            this.autoSelfHealingEnabled = false
            this.cfgData["General"].AutomaticSelfHealing = false
          } else {
            if (x.selfHealing == null) {
              x.selfHealing = false
            }

            if (x.selfHealing === true) {
              this.autoSelfHealingDisableSwitch = false
              this.autoSelfHealingEnabled = true
              this.cfgData["General"].AutomaticSelfHealing = true
            } else {
              this.autoSelfHealingDisableSwitch = true
              this.autoSelfHealingEnabled = false
              this.cfgData["General"].AutomaticSelfHealing = false
            }
          }
        })
        this.cfgData = obj
        this.loadConfigObj(obj);
      } catch (exception) {
        console.error(exception)
        console.error("Fail to load config")
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Fail to load config! Could be wrong format of JSON data! ' + exception
      }
    },
    getChildrenForSFS(sfsID) {
      for (var item of this.cfgData["ShardFileSystems"]) {
        if (item["uid"] == sfsID) {
          return item["Shardfiles"]

        }
      }
      return [];
    },
    openObject() {
      let hasSH = this.cfgData.Policies.some(policy => policy.selfHealing)
      if (hasSH && !this.manuallyDisabledAutoSelfHealing) {
        this.autoSelfHealingEnabled = true
        this.autoSelfHealingDisableSwitch = false
      } else if (hasSH && this.manuallyDisabledAutoSelfHealing) {
        this.autoSelfHealingEnabled = false
        this.autoSelfHealingDisableSwitch = false
      }
      else if (!hasSH){
        this.manuallyDisabledAutoSelfHealing = false
        this.autoSelfHealingEnabled = false
        this.autoSelfHealingDisableSwitch = true
      }
      if (!this.activeInTree.length) {
        return undefined
      }

      const uid = this.activeInTree[0]

      this.showStart = false;
      this.isGoogle = false;
      this.isAWS = false;
      this.isBackblaze = false;
      this.isWasabi = false;
      this.isDigitalOcean = false;
      this.isSMB = false;
      this.isOracle = false;
      this.isMS = false;
      this.isIBM = false;
      this.isLocal = false;
      this.showSFS = false;
      this.showSFSDefault = false;
      this.showSFC = false;
      this.showGeneral = false;
      this.showRaw = false;
      this.showPrepareConfig = false;
      this.showPolicy = false;
      this.showPolicyDefault = false;
      this.showAddSCF = false;
      this.shardContainerFileItemType = '';
      if (uid === 1010) {
        this.name = "";
        this.showStart = true
      } else if (uid === 10001) {
        this.name = "";
        this.showSFSDefault = true
      } else if (uid === 10000) {
        this.showGeneral = true
      } else if (uid === 10002) {
        this.name = "";
        this.policyItems = []
        for (var policy of this.cfgData["Policies"]) {
          this.policyItems.push(policy)
        }
        this.showPolicyDefault = true
      } else if (uid === 10003) {
        this.showOverView = true
      } else if (uid === 10006) {
        this.textData = JSON.stringify(this.cfgData, undefined, 4);
        this.showRaw = true
      } else if (uid === 10007) {
        //this.textData=JSON.stringify(this.cfgData, undefined, 4);
        this.displayKey += 1;
        this.showPrepareConfig = true
      } else {
        var configData = getConfigObject(uid, this.cfgData)
        if (configData.connector != null) {
          this.shardFileSystemItem = configData
          this.displayKey += 1;
          this.uid = configData.uid;
          this.showSFS = true;
        } else if (configData.shardFileSystem != null) {
          this.displayKey += 1;
          this.shardContainerFileItem = configData
          this.shardContainerFileItemType = getConfigObject(configData.shardFileSystem, this.cfgData).connector
          this.getAvailableShardContainerFiles();
          this.showSFC = true;
        } else if (configData.rule != null) {
          //Policy
          this.policyItem = configData;
          this.displayKey += 1;
          this.getPolicyOrders();
          this.getAvailableShardContainerFiles();
          this.showPolicy = true;
        }
      }

    },
    getIDFromConfig(){
      var uniqueUID = this.getUniqueUID(100)
      if( uniqueUID == -1){
        //Need to try higher max value
        uniqueUID = this.getUniqueUID(1000)
      }
      return uniqueUID
    },
    getOrderFromConfig(){
      var highestValue = 0;
      for (var policy of this.cfgData["Policies"]) {
        if(policy.order > highestValue){
          highestValue = policy.order
        }
      }
      highestValue++
      return highestValue
    },
    getUniqueUID(max) {
      var uniqueUID = -1
      var nrOfTries = 0
      while (uniqueUID < 0 && nrOfTries < max) {
        var tempUID = Math.floor(Math.random() * (max + 1));
        if (getConfigObject(tempUID, this.cfgData)["uid"] == null && tempUID > 0) {
          uniqueUID = tempUID;
        }else{
          console.log("Not unique! UID=" + tempUID + " already exists")
        }
        nrOfTries++
      }
      console.log("UniqueID=" + uniqueUID)
      return uniqueUID;
    },
    oldGETIDFromconfig(){
      var highest = 0;
      for (var item of this.cfgData["ShardFileSystems"]) {
        if (parseInt(item["uid"]) > highest) {
          highest = parseInt(item["uid"])
        }
        if (item["Shardfiles"] != null) {
          for (var sf of item["Shardfiles"]) {
            if (parseInt(sf["uid"]) > highest) {
              highest = parseInt(sf["uid"])
            }
          }
        }
      }
      for (var itemPol of this.cfgData["Policies"]) {
        if (parseInt(itemPol["uid"]) > highest) {
          highest = parseInt(itemPol["uid"])
        }
      }
      highest += 1;
      return highest;
    },
    addStorageSystem() {
      var newID = this.getIDFromConfig();
      var connector = "";
      this.items[this.ShardFileSystemsPosition]['children'].push({uid: newID, name: this.name, children: []});
      if (this.selectedSFSToAdd == "AWS") {
        connector = "com.shardsecure.filesystems.AWSS3Storage";
      }
      if (this.selectedSFSToAdd == "Google") {
        connector = "com.shardsecure.filesystems.GoogleStorage";
      }
      if (this.selectedSFSToAdd == "Backblaze") {
        connector = "com.shardsecure.filesystems.BackblazeStorage";
      }
      if (this.selectedSFSToAdd == "Wasabi") {
        connector = "com.shardsecure.filesystems.WasabiStorage";
      }
      if (this.selectedSFSToAdd == "DigitalOcean") {
        connector = "com.shardsecure.filesystems.DigitalOceanStorage";
      }
      if (this.selectedSFSToAdd == "SMB") {
        connector = "com.shardsecure.filesystems.SMBStorage";
      }
      if (this.selectedSFSToAdd == "Oracle") {
        connector = "com.shardsecure.filesystems.OracleStorage";
      }
      if (this.selectedSFSToAdd == "IBM") {
        connector = "com.shardsecure.filesystems.IBMCloudStorage";
      }
      if (this.selectedSFSToAdd == "Microsoft") {
        connector = "com.shardsecure.filesystems.MSAzureStorage";
      }

      if (this.selectedSFSToAdd == "Generic S3") {
        connector = "com.shardsecure.filesystems.GenericS3Storage";
      }
      if (this.selectedSFSToAdd == "ALICloud") {
        connector = "com.shardsecure.filesystems.ALICloudStorage";
      }
      if (this.selectedSFSToAdd == "Local disk") {
        connector = "com.shardsecure.filesystems.TreeLocalFileSystem";
      }
      if (this.selectedSFSToAdd == "Dropbox") {
        connector = "com.shardsecure.filesystems.DropboxStorage";
      }
      if (this.selectedSFSToAdd == "Box") {
        connector = "com.shardsecure.filesystems.BoxStorage";
      }

      var newSFS = {connector: connector, name: this.name, uid: newID, Shardfiles: []};
      if (this.selectedSFSToAdd == "DigitalOcean") {
        newSFS['param3'] = false
      }
      this.cfgData["ShardFileSystems"].push(newSFS);
      this.activeInTree = [newID]
      this.openObject()
      this.addStorageSystemDialog = false
      this.selectedSFSToAdd = ""
    },
    showAddSFInput() {
      this.name = "";
      this.fullFilePath = "";
      this.blockSize = 4
      this.pathLabel = "Bucket"
      this.showSMBPath = false
      try{
        if (getConfigObject(this.activeInTree[0], this.cfgData).connector === 'com.shardsecure.filesystems.TreeLocalFileSystem') {
          this.pathLabel = "Full File Path"
        }else if (getConfigObject(this.activeInTree[0], this.cfgData).connector === 'com.shardsecure.filesystems.SMBStorage') {
          this.pathLabel = "Path"
          this.showSMBPath = true
          this.smbShare = getConfigObject(this.activeInTree[0], this.cfgData).connectionString
        }
      } catch (exception) {
        console.log("Could not find SFS object")
      }
      this.showSFS = false;
      this.showAddSCF = true;
    },
    addSCF() {
      var newID = this.getIDFromConfig();
      var sfsUID = this.uid;
      var newSF = {
        name: this.name,
        fullFilePath: this.fullFilePath,
        uid: newID,
        shardFileSystem: sfsUID,
        blockSize: parseInt(this.blockSize),
        active: true
      };
      //Need to find correct SF to add SF to
      for (var item of this.cfgData["ShardFileSystems"]) {

        if (item["uid"] == this.uid) {
          if (item["Shardfiles"] == null) {
            item["Shardfiles"] = [];
          }
          item["Shardfiles"].push(newSF);
          for (var itemInTree of this.items[this.ShardFileSystemsPosition]['children']) {
            if (itemInTree['uid'] == sfsUID) {
              var children = this.getChildrenForSFS(sfsUID);
              itemInTree['children'] = children
            }
          }
        }
      }

      this.activeInTree = [newID]
      this.openObject()
    },
    addPolicy() {
      if(this.name.length == 0){
        return
      }
      var newID = this.getIDFromConfig();
      var order = this.getOrderFromConfig();
      var newPolicy = {name: this.name, uid: newID, rule: "", active: true, shuffle: true, compression: true, compressionAlgo: 'lz4', order: order};
      this.cfgData["Policies"].push(newPolicy);
      this.activeInTree = [newID]
      this.addPolicyDialog = false
      this.openObject()
    },
    getPolicyOrders() {
      for (var policy of this.cfgData["Policies"]) {
        this.policyOrders[policy.uid] = {'order': policy.order, 'name': policy.name};
      }
    },
    getAvailableShardContainerFiles() {
      this.availableShardContainerFiles = [];
      for (var item of this.cfgData["ShardFileSystems"]) {
        if (item["Shardfiles"] != null) {
          for (var sfItem of item["Shardfiles"]) {
            if (sfItem["active"] &&  !sfItem["isSecondary"]) {
              var displayName = sfItem["name"]
              var secondaryDisplayName = ""
              if( sfItem["secondaryStorageId"] != null && sfItem["secondaryStorageId"] > 0){
                if(getConfigObject(sfItem["secondaryStorageId"], this.cfgData) != null){
                  //displayName = displayName + "<v-icon color='green'>mdi-arrow-right-bold</v-icon>" + getConfigObject(sfItem["secondaryStorageId"], this.cfgData)["name"] + ")"
                  secondaryDisplayName = getConfigObject(sfItem["secondaryStorageId"], this.cfgData)["name"]
                }else{
                  //JTOdisplayName = displayName + " (" + sfItem["secondaryStorageId"] + ")"
                  secondaryDisplayName = sfItem["secondaryStorageId"]
                }
              }
              this.availableShardContainerFiles[sfItem["uid"]] = { 'name': displayName, 'secondaryDisplayName': secondaryDisplayName}
            }
          }
        }
      }

    },
    deletePolicy(deletePolicy) {
      var pos = 0;
      for (var policy of this.cfgData["Policies"]) {
        if (deletePolicy == policy['uid']) {
          this.cfgData["Policies"].splice(pos, 1); //[pos]
          this.showPolicy = false;
          this.activeInTree = ["10002"]
          this.openObject();
          this.showPolicyDefault = true;
          return;
        }
        pos += 1;
      }
    },
    saveConfigToFile() {
      if (this.cfgData["General"].AutomaticSelfHealing == null) {
        this.cfgData["General"].AutomaticSelfHealing = this.autoSelfHealingEnabled
      }
      var link = document.createElement("a");
      link.setAttribute("target", "_blank");
      if (Blob !== undefined) {
        var blob = new Blob([JSON.stringify(this.cfgData, undefined, 4)], {type: "text/plain"});
        link.setAttribute("href", URL.createObjectURL(blob));
      } else {
        link.setAttribute("href", "data:text/plain," + encodeURIComponent(JSON.stringify(this.cfgData, undefined, 2)));
      }
      link.setAttribute("download", "shardsec.json");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    prepareConfig() {

    },
    changedAttribute(a) {
      this.lastEdit = new Date();
      this.cfgData["lastEdit"] = this.lastEdit
      var jsonPos = "";
      if (this.showGeneral) {
        jsonPos = "General"
      } else if (this.showSFS) {
        jsonPos = "";
        //Need to find correct SFS to update
        for (var item of this.cfgData["ShardFileSystems"]) {
          if (item["uid"] == this.uid) {
            if (a.target.type == "number") {
              item[a.target.name] = parseInt(this[a.target.name]);
            } else {
              item[a.target.name] = this[a.target.name];
            }
          }
        }
        return;
      } else if (this.showStart) {
        jsonPos = "";
        if (a.target.type == "number") {
          this.cfgData[a.target.name] = parseInt(this[a.target.name]);
        } else {
          this.cfgData[a.target.name] = this[a.target.name];
        }
        return
      } else if (this.showSFC) {
        jsonPos = "";
        //Need to find correct SF to update
        for (item of this.cfgData["ShardFileSystems"]) {
          if (item["Shardfiles"] != null) {
            for (var sfItem of item["Shardfiles"]) {
              if (sfItem["uid"] == this.uid) {
                if (a.target.type == "number") {
                  sfItem[a.target.name] = parseInt(this[a.target.name]);
                } else {
                  sfItem[a.target.name] = this[a.target.name];
                }
              }
            }
          }
        }
        return;
      }
      if (jsonPos.length > 0) {
        var addType = "";
        if (a.target.type == "number") {
          this.cfgData[jsonPos][a.target.name] = parseInt(this[a.target.name]);
        } else {
          if (this[a.target.name + "2"] != null) {
            addType = this[a.target.name + "2"];
          }
          this.cfgData[jsonPos][a.target.name] = this[a.target.name] + addType;
        }
      } else {
        // eslint-disable-next-line
        console.log("Dont know where to save data.....")
      }
    },
    getIcon(uidToFind) {
      var color = '#1B5E20';
      var icon = 'mdi-database';
      if (uidToFind != null) {
        if (uidToFind == "1010") {
          icon = 'mdi-star'
          color = 'green'
        } else if (uidToFind == "10000") {
          icon = 'mdi-cogs'
          color = 'blue'
        } else if (uidToFind == "10001") {
          icon = 'mdi-arrow-decision'
          color = '#01579B'
        } else if (uidToFind == "10002") {
          icon = 'mdi-fan'
          color = '#4E342E'
        } else if (uidToFind == "10003") {
          icon = 'mdi-arrow-expand-all'
          color = 'orange'
        } else if (uidToFind == "10006") {
          icon = 'mdi-eye'
          color = '#424242'
        } else if (uidToFind == "10007") {
          icon = 'mdi-rocket'
          color = '#1B5E20'
        } else {

          for (var x = 0; x < this.cfgData["ShardFileSystems"].length; x++) {
            var item = this.cfgData["ShardFileSystems"][x];
            if (item.uid == uidToFind) {
              if (item.connector.includes('AWS')) {
                icon = 'mdi-aws'
                color = 'orange'
              } else if (item.connector.includes('Google')) {
                icon = 'mdi-folder-google-drive'
                color = '#FDD835'
              } else if (item.connector.includes('Backblaze')) {
                icon = 'mdi-cloud'
                //icon = SvgIcon.BackblazeIcon
                color = '#D23735'
              }else if (item.connector.includes('Wasabi') ) {
                icon = 'mdi-cloud'
                color = '#62CF61'
              }else if (item.connector.includes('DigitalOcean') ) {
                icon = 'mdi-cloud'
                color = '#0080FF'
              }else if (item.connector.includes('SMB')) {
                icon = 'mdi-nas'
                color = '#007FFF'
              }else if (item.connector.includes('Oracle')) {
                icon = 'mdi-cloud'
                color = '#D23735'
              } else if (item.connector.includes('MS')) {
                icon = 'mdi-microsoft'
                color = '#007FFF'
              } else if (item.connector.includes('Tree')) {
                icon = 'mdi-nas'
                color = '#263238'
              } else if (item.connector.includes('IBM')) {
                icon = 'mdi-weather-cloudy'
                color = 'blue'
              } else if (item.connector.includes('GenericS3')) {
                icon = 'mdi-cloud'
                color = '#4169E1'
              }else if (item.connector.includes('ALICloud')) {
                icon = 'mdi-cloud'
                color = '#FF6701'
              }
            }
            if (item.Shardfiles != null) {
              for (var y = 0; y < item.Shardfiles.length; y++) {
                var container = item.Shardfiles[y];
                if (container.uid == uidToFind) {
                  //return container;
                  if( container.isSecondary){
                    icon = 'mdi-database-plus'
                    color = 'orange'
                  }
                }
              }
            }
          }
          for (var i = 0; i < this.cfgData["Policies"].length; i++) {
            var item2 = this.cfgData["Policies"][i];
            if (item2.uid == uidToFind) {
              icon = 'mdi-file-document' //mdi-kodi
              color = 'indigo'
            }
          }
        }
      }
      return {color: color, icon: icon}
    }
  },
  computed: {},
  mounted() {
    this.loadOnStart();

    EventBus.$on('changedPolicyOrder', changedPolicyOrder => {
      if (changedPolicyOrder) {
        this.items[this.PoliciesPosition]['children'] = this.cfgData["Policies"].sort((a, b) => a.order - b.order);
        this.failOrderText = ""
        for (var policyTmp of this.cfgData["Policies"]) {
          if (policyTmp['uid'] != this.uid) {
            this.failOrderText = "Note: This is same order as '" + policyTmp['name'] + "'";
          }
        }
      }
    })
    EventBus.$on('showAddSFInput', showAddSFInput => {
      if (showAddSFInput) {
        this.showAddSFInput()
      }
    })
    EventBus.$on('deletePolicyID', deletePolicyID => {
      if (deletePolicyID) {
        this.deletePolicy(deletePolicyID)
      }
    })
  }
};
</script>
