<template>
<v-container>
  <v-text-field v-model="uuid" label="UUID"></v-text-field>
  <v-btn small :color="themeColor" class="white--text" @click="redirect">Ok</v-btn>
</v-container>
</template>

<script>
export default {
  name: "Start",
  props: ['themeColor'],
  data: () => ({
    uuid: ""
  }),
  methods: {
    redirect(){
      console.log("Start")
      window.location.href = "/?" + this.uuid;
    }
  }
}
</script>

<style scoped>

</style>