/* eslint-disable */

const SHARDSECURE_STORAGE_CLASS_STANDARD = "Standard"
const SHARDSECURE_STORAGE_CLASS_COLD = "Cold"
const SHARDSECURE_STORAGE_CLASS_OFFLINE = "Offline"

const STANDARD_COLOR = "primary"
const COLD_COLOR = "blue lighten-4"
const OFFLINE_COLOR = "orange"

const STORAGE_CLASSES = {
    "com.shardsecure.filesystems.AWSS3Storage": [
        {
            name: "Standard Infrequent Access",
            id: "STANDARD_IA",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        },
        {
            name: "Glacier Instant Retrieval",
            id: "GLACIER_IR",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        }
    ],
    "com.shardsecure.filesystems.OracleStorage": [
        {
            name: "Standard",
            id: "STANDARD",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        },
        {
            name: "InfrequentAccess",
            id: "INFREQUENT_ACCESS",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        }
    ],
    "com.shardsecure.filesystems.GoogleStorage": [
        {
            name: "Nearline",
            id: "NEARLINE",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        },
        {
            name: "Coldline",
            id: "COLDLINE",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        },
        {
            name: "Archive",
            id: "ARCHIVE",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        }
    ],
    "com.shardsecure.filesystems.MSAzureStorage": [
        {
            name: "Cool",
            id: "COOL",
            shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_COLD,
            color: COLD_COLOR
        }
    ]
}

function storageClassSortFunc(class1, class2) {
    if (class1.shardsecureStorageClass === class2.shardsecureStorageClass) {
        return class1.name.localeCompare(class2.name)
    } else if (class1.shardsecureStorageClass === "STANDARD") {
        return -1
    } else if (class2.shardsecureStorageClass === "STANDARD") {
        return 1
    } else if (class1.shardsecureStorageClass === "COLD") {
        return -1
    } else if (class2.shardsecureStorageClass === "COLD") {
        return 1
    }
}

export function getSupportedStorageClasses(connector) {
    let supportedClasses = [{
        name: "Standard",
        id: "STANDARD",
        shardsecureStorageClass: SHARDSECURE_STORAGE_CLASS_STANDARD,
        color: STANDARD_COLOR
    }]

    if (STORAGE_CLASSES[connector]) {
        supportedClasses.push(...STORAGE_CLASSES[connector])
    }

    return supportedClasses.sort(storageClassSortFunc);
}