<template>
  <v-container>
    <v-card
        :key="uid"
        class="pt-6 mx-auto"
        flat
        style="width: 400px"
    >
      <v-card-text>

        <h3 class="headline mb-2" :title="uid">
          {{ name }}
        </h3>
      </v-card-text>
      <v-divider></v-divider>
      <table>
        <tr>
          <td>
            <v-switch
                v-model="active"
                name="active"
                label="active"
                @change="changedType(active, 'active')"
            ></v-switch>
          </td>
          <td width="40px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>
            <v-switch
                v-model="isSecondary"
                name="isSecondary"
                label="Secondary Storage"
                :disabled="checkIfAllowSetSecondary()"
                @change="changedType(isSecondary, 'isSecondary')"
            ></v-switch>
          </td>
        </tr>
        <tr v-if="!isSecondary">
          <td>
            <v-text-field v-model="secondaryName" label="Secondary Container" sm="12" name="secondaryName" readonly></v-text-field>
          </td>
          <td>
            <v-btn small :color="themeColor" class="white--text" right @click="selectSecondaryShardContainerFileDialog = true;">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </table>
      <v-text-field v-if="isSecondary" v-model="primaryName" label="Primary Container" sm="12" name="primaryName" readonly></v-text-field>
      <v-text-field v-model="name" label="Name" name="name" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-model="blockSize" label="Shard Size" sm="12" name="blockSize" type="number"
                    readonly></v-text-field>
      <v-text-field v-model="fullFilePath" :label="pathLabel" name="fullFilePath"
                    @input.native="changedAttribute"></v-text-field>

      <div style="text-align: left;font-style: italic;" v-if="showSMBPath">\\{{ connectionString }}\{{ fullFilePath }}</div>
      <v-select label="Storage Class"
                v-if="showStorageClass"
                v-model="storageClass"
                :items="supportedStorageClasses"
                item-text="name"
                item-value="id"
                return-object
                @change="changedStorageClass">
        <template v-slot:item="{item}">
          <div style="display: flex; justify-content: space-between; width: 100%">
            {{ item.name }}
            <v-chip small :color="item.color">{{ item.shardsecureStorageClass }}</v-chip>
          </div>
        </template>
        <template v-slot:selection="{item}">
          <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
            {{ item.name }}
            <v-chip small :color="item.color">{{ item.shardsecureStorageClass }}</v-chip>
          </div>
        </template>
        <template v-slot:append-outer>
          <v-tooltip top max-width="450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            The storage class types have impact on both cost and performance. Short explanation of the types:
            <br>
            <ul>
              <li><strong>Standard: </strong>The highest cost but also the most performant</li>
              <li><strong>Cold: </strong>Lower cost but the objects are still retrieved instantly</li>
              <li><strong>Offline: </strong>The lowest cost but the objects are retrieved in the background and the ShardSecure engine must wait until the objects are available</li>
            </ul>
          </v-tooltip>
        </template>
      </v-select>


    </v-card>
    <v-dialog v-model="selectSecondaryShardContainerFileDialog"
              hide-overlay
              persistent
              scrollable
              width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          SelectSecondary Shard Container File
        </v-card-title>
        <v-card-text>
          Available Secondary Shard Container Files
            <v-radio-group v-model="secondaryStorageId" @change="changeSecondary">
              <v-radio
                  v-for="(item,index) in availableSecondaryShardContainerFiles" :key="index"
                  :label=item.name
                  :value=item.index
              ></v-radio>
            </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-btn :color="themeColor" class="white--text" text @click="selectSecondaryShardContainerFileDialog = false">Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import {getSupportedStorageClasses} from "@/service/StorageClassService";

export default {
  name: "ShardContainerFile",
  props: ['shardContainerFileItem', 'shardContainerFileItemType','themeColor', 'shardFileSystems'],
  data: () => ({
    uid: "",
    active: true,
    isSecondary: false,
    primaryName: '',
    secondaryName: '',
    secondaryStorageId: -1,
    primaryStorageId: -1,
    isSecondaryWithPrimary: true,
    blockSize: 0,
    fullFilePath: "",
    name: "",
    pathLabel: "Bucket",
    storageClass: "",
    showStorageClass: true,
    showSMBPath: false,
    connectionString: "",
    supportedStorageClasses: [],
    selectSecondaryShardContainerFileDialog: false,
    availableSecondaryShardContainerFiles: []
  }),
  methods: {
    changedStorageClass(newClass) {
      this.shardContainerFileItem.storageClass = newClass.id
    },
    changeSecondary(){
      if( this.secondaryStorageId > 0){
        this.shardContainerFileItem['secondaryStorageId'] = parseInt(this.secondaryStorageId)
      }else{
        delete this.shardContainerFileItem['secondaryStorageId']
        this.secondaryName = ""
      }
      //EventBus.$emit('updatedSelectedSecondaryShardContainerFile', this.shardContainerFileItem['uid'])
      //Need to look through all containerfiles to remove and add primaryStorageId
      var secondaryStorageId = -1
      for (var item of this.shardFileSystems) {
        if (item["Shardfiles"] != null) {
          for (var sfItem of item["Shardfiles"]) {
            if ( sfItem["uid"] == this.uid) {
              if( sfItem["secondaryStorageId"] != null ){
                secondaryStorageId = sfItem["secondaryStorageId"]
              }
            }
          }
        }
      }
      for (item of this.shardFileSystems) {
        if (item["Shardfiles"] != null) {
          for (sfItem of item["Shardfiles"]) {
            if ( sfItem["primaryStorageId"] == this.uid && sfItem["uid"] != secondaryStorageId) {
              delete sfItem["primaryStorageId"]
            }else if(sfItem["uid"] == secondaryStorageId ){
              sfItem["primaryStorageId"] = this.uid
              this.secondaryName = sfItem["name"]
            }
          }
        }
      }
    },
    checkIfAllowSetSecondary(){
      if( this.isSecondary && this.primaryStorageId >= 0 ){
        return true
      }else if( (this.isSecondary == null || ! this.isSecondary) && this.secondaryStorageId >= 0  ){
        return true
      }
      return false
    },
    changedAttribute(a) {
      if (a.target.type === "number") {
        this.shardContainerFileItem[a.target.name] = parseInt(this[a.target.name]);
      } else {
        this.shardContainerFileItem[a.target.name] = this[a.target.name];
      }
    },
    changedType(type, inputObject) {
      this.shardContainerFileItem[inputObject] = type;
    },
  },
  mounted() {
    this.supportedStorageClasses = getSupportedStorageClasses(this.shardContainerFileItemType)
    this.storageClass = this.supportedStorageClasses[0]

    for (let item in this.shardContainerFileItem) {
      if (this[item] != null) {
        this[item] = this.shardContainerFileItem[item];
      }
    }
    this.showStorageClass = true
    this.showSMBPath = false
    if (this.shardContainerFileItemType === 'com.shardsecure.filesystems.TreeLocalFileSystem') {
      this.pathLabel = "Full File Path"
      this.showStorageClass = false
    }else if (this.shardContainerFileItemType === 'com.shardsecure.filesystems.SMBStorage') {
      this.pathLabel = "Path"
      this.showStorageClass = false
      this.showSMBPath = true
    }else if (this.shardContainerFileItemType === 'com.shardsecure.filesystems.WasabiStorage' || this.shardContainerFileItemType === 'com.shardsecure.filesystems.DigitalOceanStorage') {
      this.showStorageClass = false
    }
    //shardFileSystems
    this.availableSecondaryShardContainerFiles = []
    this.availableSecondaryShardContainerFiles.push({ 'name': "No Secondary", 'index': -1});
    for (var item of this.shardFileSystems) {
      console.log(item.uid + ":" + this.shardContainerFileItem.shardFileSystem)
      if( item != null && item.uid != null && item.uid == this.shardContainerFileItem.shardFileSystem ){
        this.connectionString = item.connectionString
      }
      if (item["Shardfiles"] != null) {
        for (var sfItem of item["Shardfiles"]) {
          if (sfItem["active"] && sfItem["isSecondary"] && sfItem["blockSize"] == this.blockSize ) {
            if (sfItem["primaryStorageId"] == null || sfItem["primaryStorageId"] == this.uid) {
              this.availableSecondaryShardContainerFiles.push({'name': sfItem["name"], 'index': sfItem["uid"]});
            }
          }else if( this.isSecondary && this.primaryStorageId > 0 && sfItem["uid"] == this.primaryStorageId){
            this.primaryName = sfItem["name"]
          }
          if( !this.isSecondary && this.secondaryStorageId > 0 && sfItem["uid"] == this.secondaryStorageId ){
            this.secondaryName = sfItem["name"]
          }
        }
      }
    }
  },
}
</script>

<style scoped>

</style>