<template>
  <v-container align-start grid-list-md width="800">
    <v-layout text-xs-left width="800" >
        <v-card
                flat
                width="800"
        >

          <v-card-text>

            <h3 class="headline mb-2">
              ShardSecure config
            </h3>
            <v-alert
                     outlined
                     type="warning"
            >
            Make sure to save config first!
            </v-alert>
          </v-card-text>
          <v-divider></v-divider>
          <v-row style="height: 80px;"
                 class="text-left"
                 tag="v-card-text"
          >
            <v-col class="d-flex" sm="20">
              <v-text-field v-model="configKey" label="Config key"></v-text-field>
              <v-btn :disabled="!configKey" class="white--text" small :color="themeColor"  @click="genData"><v-icon right dark>mdi-file-key</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row><v-col><v-btn :color="themeColor" class="white--text" @click="copyToClipBoard">Copy</v-btn></v-col></v-row>
          <v-row style="height: 80px;"
                 class="text-left"
                 tag="v-card-text"
          >
            <v-col class="d-flex" sm="20">
              <v-textarea
                      :disabled="configDataPreparedDisabled"
                      solo
                      v-model="configDataPrepared"
                      label=""
                      height="200"
                      ref="configDataPreparedRef"
              />
            </v-col>
          </v-row>

        </v-card>

    </v-layout>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
  const crypto = require('crypto');
  export default {
    name: "PrepareConfig",
    props: ['configData', 'themeColor'],
    data: () => ({
      configKey: "",
      configDataPrepared: "",
      snack: false,
      snackColor: '',
      snackText: '',
      configDataPreparedDisabled: true
    }),
    methods:{
      genData(){
        if( this.configKey.length == 0 ){
          this.snack = true
          this.snackColor = 'error'
          this.snackText = 'No config key'
          return
        }
        //this.configDataPrepared = response.data.message
        try {
          var decodedKey = this.configKey.substring(0, this.configKey.indexOf( ':' ));
          var initVector = this.configKey.substring( this.configKey.indexOf( ':' ) + 1 );
          const cipher = this.encrypt(JSON.stringify(this.configData), decodedKey, initVector)
          this.configDataPrepared = cipher
          return cipher;
        } catch ( error ) {
          console.log(error);
        }
      },
      encrypt(dataInput, keyBase64, ivBase64){
        let iv = Buffer.from(ivBase64, 'base64');
        let cipher = crypto.createCipheriv('aes-128-gcm', Buffer.from(keyBase64, 'base64'), iv);
        let encryptedData = Buffer.concat([cipher.update( Buffer.from(dataInput, 'utf-8')), cipher.final()]);
        return Buffer.concat([encryptedData,cipher.getAuthTag()]).toString('base64');
      },
      copyToClipBoard(){
        const el = document.createElement('textarea');
        el.value = this.configDataPrepared;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    }
  }
</script>

<style scoped>

</style>