<template>
  <v-app>
    <v-app-bar app>

      <v-toolbar-title class="headline text-uppercase">
        <v-img
                :src="require('./assets/logo.png')"
                class="my-3"
                contain
                height="40"
        ></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <Admin v-if="hasCustomerUUID" :themeColor="themeColor"/>
      <Start v-else :themeColor="themeColor"/>
    </v-main>
    <v-footer class="pa-3" fixed app>
      <p :title="configuiversion">ShardSecure&copy; Config UI</p>
      <v-spacer></v-spacer>
      <div> {{ new Date().getFullYear() }}</div>
    </v-footer>
  </v-app>
</template>

<script>
import Admin from './components/Admin';
import Start from './components/Start'

export default {
  name: 'App',
  components: {
    Admin,
    Start
  },
  data: () => ({
    themeColor: "light-blue darken-4",
    configuiversion: "2.0.2",
    hasCustomerUUID: false
  }),
  methods: {
  },
  mounted() {
    if(window.location.href.indexOf("?") > -1){
      var query = window.location.href.substring(window.location.href.indexOf("?")+1)
      if( query != null && query.length > 0 ){
        this.hasCustomerUUID = true
      }
    }

  }
};
</script>
