<template>

  <v-container fluid grid-list-md>
    <v-flex v-for="item in overViewData"
            :key="item.uid">
      <v-card
           width="650"
          elevation="10"
          outlined
      >
        <v-card-title
            :style="getFontStyle(item.active)"
        >{{ item.name }}
          ({{ item.order }})
        </v-card-title>
        <v-card-subtitle>{{ item.description }}</v-card-subtitle>
        <v-card-text>
          <!-- <span>{{ getRuleTextLocal(item.rule)}}</span>
          <span style="horiz-align: left;"  v-if="item.compression">{{ item.compressionAlgo }} compression, </span>
          <span style="horiz-align: left;" v-if="!item.compression">No compression, </span>
          <span v-if="item.selfHealing">Self healing</span>
          <span v-if="item.shuffle">Shuffle</span> -->
          <div align="left"><h4>Shard Containers:</h4></div>
          <table>
            <tr v-for="shardFile in item.shardContainerFiles"  :key="shardFile.index">
              <td style="horiz-align: left;">{{ shardFile.name }}</td>
              <td>
                <v-icon v-if="shardFile.secondaryStorage.length > 0" color="green">mdi-arrow-right-bold</v-icon>
              </td>
              <td>
                <div v-if="shardFile.secondaryStorage.length > 0">{{ shardFile.secondaryStorage }}</div>
              </td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>

</template>

<script>
import {getConfigObject, getRuleText} from "../service/Common";
export default {
  name: "ConfigOverview",
  props: ['themeColor', 'configData'],
  data: () => ({
    headers: [

      {
        text: 'Policy',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Description',
        align: 'left',
        sortable: true,
        value: 'description',
      },
      {
        text: 'Active',
        align: 'left',
        sortable: true,
        value: 'active',
      },
      {
        text: 'Order',
        align: 'left',
        sortable: true,
        value: 'order'
      },
      {
        text: 'Rule',
        align: 'left',
        sortable: true,
        value: 'rule'
      },
      {
        text: 'Compression',
        align: 'left',
        sortable: true,
        value: 'compression'
      },
      {
        text: 'Shuffle',
        align: 'left',
        sortable: true,
        value: 'shuffle'
      },
      {
        text: 'Digest',
        align: 'left',
        sortable: true,
        value: 'digest'
      },
      {
        text: 'Updated',
        align: 'left',
        sortable: true,
        value: 'updated'
      },
      {
        text: 'Primary Buckets',
        align: 'left',
        sortable: true,
        value: 'primaryBuckets'
      },
      {
        text: 'Secondary Buckets',
        align: 'left',
        sortable: true,
        value: 'secondaryBuckets'
      }],
    overViewData: []
  }),
  methods: {
    buildOverviewData() {
      this.overViewData = []
      var indexNr = 0;
      if (this.configData != null && this.configData["Policies"] != null) {
        for (var policy of this.configData["Policies"]) {
          var policyTmp = JSON.parse(JSON.stringify(policy));
          console.log(policyTmp)
          var shardContainerFiles = []
          if( policyTmp.shardContainerFiles != null){
            for(var containerFileUID of policyTmp.shardContainerFiles){
              var containerFile = getConfigObject(containerFileUID, this.configData)
              var secondaryStorageName = ''
              if( containerFile.secondaryStorageId >= 0  ){
                secondaryStorageName = getConfigObject(containerFile.secondaryStorageId, this.configData).name
              }

              shardContainerFiles.push({'index': indexNr, 'name': containerFile.name, 'secondaryStorage': secondaryStorageName})
              indexNr++
            }
          }
          policyTmp["shardContainerFiles"] = shardContainerFiles
          this.overViewData.push(policyTmp)
        }
        this.overViewData.sort(function (a, b) {
          return parseFloat(a.order) - parseFloat(b.order)
        });
      }
    },
    getRuleTextLocal(currRule){
      var text = getRuleText(currRule);
      console.log(text)
      return text
    },
    getFontStyle(isActive){
      if( isActive != null &&  !isActive){
        return "font-style:italic;color: orange;"
      }
      return "color: green;"
    }
  },
  mounted() {
    this.buildOverviewData()
  }
}
</script>

<style scoped>

</style>