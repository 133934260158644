<template>
  <v-container>
    <v-card
            :key="uid"
            class="pt-6 mx-auto"
            flat
    >
      <v-card-text>
        <v-avatar
                v-if="type"
                size="150"
                rounded="0"
        >
          <v-img
                  :src="require('../assets/' + type + '.png')"
                  class="my-3"
                  contain
          ></v-img>
        </v-avatar>
        <h3 class="headline mb-2" :title="uid">
          {{ typeLabel }}
        </h3>
        <v-btn small :color="themeColor" class="white--text" @click="showAddSFInput">Add SCF</v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-text-field v-model="name" label="Name" name="name" @input.native="changedAttribute"></v-text-field>
      <div align="left" v-if="showUserNameAsTextArea">{{ userNameLabel }}</div>
      <v-textarea
              v-if="showUserNameAsTextArea"
              solo
              name="username"
              v-model="username"
              height="200"
              @input.native="changedAttribute"
      />
      <v-text-field v-else-if="showUserName" v-model="username" :label="userNameLabel" name="username" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-if="showPassword" v-model="password" :label="passwordLabel" name="password" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-if="showConnectionString" v-model="connectionString" :label="connectionStringLabel" name="connectionString" @input.native="changedAttribute"></v-text-field>

      <v-text-field v-if="showParam1" v-model="param1" :label="param1Label" name="param1" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-if="showParam2" v-model="param2" :label="param2Label" name="param2" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-if="showParam3" v-model="param3" :label="param3Label" name="param3" @input.native="changedAttribute"></v-text-field>
      <v-switch
          v-if="showParam3switch"
          v-model="param3"
          name="param3"
          :label="param3Label"
          @change="changedType(param3, 'param3')"
      ></v-switch>
      <v-text-field v-if="showParam4" v-model="param4" :label="param4Label" name="param4" @input.native="changedAttribute"></v-text-field>
      <!--
      <v-text-field v-model="param2" label="param2" name="param2" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-model="param3" label="param3" name="param3" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-model="param4" label="param4" name="param4" @input.native="changedAttribute"></v-text-field>
      <v-text-field v-model="param5" label="param5" name="param5" @input.native="changedAttribute"></v-text-field>
      -->

    </v-card>
  </v-container>
</template>

<script>
  import {EventBus} from "../Events";

  export default {
    name: "ShardFileSystem",
    props: ['shardFileSystemItem', 'themeColor'],
    data: () => ({
      uid: "",
      name: "",
      username: "",
      password: "",
      connectionString: "",
      param1: "",
      param2: "",
      param3: "",
      param3Items: [],
      param4: "",
      param5: "",
      connector: "",
      showUserName: false,
      showUserNameAsTextArea: false,
      showPassword: false,
      showParam1: false,
      showParam2: false,
      showParam3: false,
      showParam3switch: false,
      showParam4: false,
      showParam5: false,
      show: false,
      showConnectionString: false,
      userNameLabel: "",
      passwordLabel: "",
      connectionStringLabel: "",
      param1Label: "",
      param2Label: "",
      param3Label: "",
      param4Label: "",
      param5Label: "",
      type: "Unknown",
      typeLabel: "",
      googlestorage: 'Google Cloud Storage',
      localfilesystem: 'Local file storage',
      awss3: 'Amazon S3',
      backblaze: 'Backblaze Storage',
      wasabistorage: 'Wasabi Storage',
      digitaloceanstorage: 'DigitalOcean Storage',
      smb: 'SMB Storage',
      oracle: 'Oracle Storage',
      ibmstorage: 'IBM Cloud Object Storage',
      msazstorage: 'Microsoft Azure Blob Storage',
      dropboxstorage: 'Dropbox cloud storage',
      boxstorage: 'Box cloud storage',
      generics3: 'Generic S3 Storage',
      alicloud: 'ALICloud OSS Storage'
    }),
    methods: {
      changedAttribute(a) {
        if( a.target.type == "number" ) {
          this.shardFileSystemItem[a.target.name] = parseInt(this[a.target.name]);
        }else{
          this.shardFileSystemItem[a.target.name] = this[a.target.name];
        }
        return;

      },
      changedType(type, inputObject){
        this.shardFileSystemItem[inputObject] = type;
      },
      showAddSFInput(){
        EventBus.$emit('showAddSFInput', true)
      },
      getShardFileSystemType(){
        //var type;
        switch( this.connector ) {
          case "com.shardsecure.filesystems.AWSS3Storage":
            this.type = "awss3";
            this.showUserName = true;
            this.showPassword = true;
            this.showParam1 = true;
            this.userNameLabel = "Access key ID";
            this.passwordLabel = "Secret access key";
            this.param1Label = "Region";
            this.typeLabel = "AWS S3 Shard File System";
            break;
          case "com.shardsecure.filesystems.Multifilesystem":
            this.type = "localfilesystem";
            this.typeLabel = "Local Shard File System";
            break;
          case "com.shardsecure.filesystems.TreeLocalFileSystem":
            this.type = "localfilesystem";
            this.typeLabel = "Local Shard File System";
            break;
          case "com.shardsecure.filesystems.GoogleStorage":
            this.type = "googlestorage";
            this.showUserNameAsTextArea = true;
            this.userNameLabel = "Google JSON credentials";
            this.typeLabel = "Google Shard File System";
            break;
          case "com.shardsecure.filesystems.BackblazeStorage":
            this.type = "backblaze";
            this.showUserName = true;
            this.showPassword = true;
            this.showParam1 = true;
            this.userNameLabel = "Application Key ID";
            this.passwordLabel = "Application Key";
            this.param1Label = "User Agent";
            this.typeLabel = "Backblaze Shard File System";
            break;
          case "com.shardsecure.filesystems.WasabiStorage":
            this.type = "wasabistorage";
            this.showUserName = true;
            this.showPassword = true;
            this.userNameLabel = "Wasabi Key ID";
            this.passwordLabel = "Wasabi Key Secret";
            this.typeLabel = "Wasabi Shard File System";
            this.showParam1 = true;
            this.param1Label = "Region";
            this.showParam2 = true;
            this.param2Label = "Endpoint";
            break;
          case "com.shardsecure.filesystems.DigitalOceanStorage":
            this.type = "digitaloceanstorage";
            this.showUserName = true;
            this.showPassword = true;
            this.userNameLabel = "Spaces Key";
            this.passwordLabel = "Spaces Secret";
            this.typeLabel = "DigitalOcean Shard File System";
            this.showParam2 = true;
            this.param2Label = "Origin Endpoint (excluding bucket name)";
            break;
          case "com.shardsecure.filesystems.SMBStorage":
            this.type = "smb";
            this.showUserName = true;
            this.showPassword = true;
            this.showConnectionString = true;
            this.connectionStringLabel = "Share name";
            this.showParam1 = true;
            this.userNameLabel = "User name";
            this.passwordLabel = "Password";
            this.param1Label = "Server name";
            this.showParam2 = true;
            this.param2Label = "Domain name";
            this.typeLabel = "SMB Shard File System";
            break;
          case "com.shardsecure.filesystems.OracleStorage":
          console.log("oracle")
              this.type = "oracle";
              this.showUserName = true;
              this.showPassword = true;
              this.showParam1 = true;
              this.showParam2 = true;
              this.showParam3 = true;
              this.showParam4 = true;
              this.userNameLabel = "User ID";
              this.passwordLabel = "Finger Print";
              this.param1Label = "Pem File Path";
              this.param2Label = "Pass Phrase";
              this.param3Label = "Tenant ID";
              this.param4Label = "Region";
              this.typeLabel = "Oracle Shard File System";
              break;
          case "com.shardsecure.filesystems.IBMCloudStorage":
            this.type = "ibmstorage";
            this.showUserName = true;
            this.showPassword = true;
            this.showParam1 = true;
            this.userNameLabel = "IBM USER ID";
            this.passwordLabel = "IBM PASSWORD";
            this.param1Label = "IBM REGION";
            this.typeLabel = "IBM Shard File System";
            this.showConnectionString = true;
            this.connectionStringLabel = "Endpoint URL";
            break;
          case "com.shardsecure.filesystems.MSAzureStorage":
            this.type = "msazstorage";
            this.showUserName = true;
            this.showPassword = true;
            this.userNameLabel = "Azure USER ID";
            this.passwordLabel = "Azure PASSWORD";
            this.typeLabel = "Microsoft Shard File System";
            this.showConnectionString = true;
            this.connectionStringLabel = "Connection String";
            break;
          case "com.shardsecure.filesystems.DropboxStorage":
            this.type = "dropboxstorage";
            this.typeLabel = "Dropbox Shard File System";
            this.showConnectionString = true;
            this.connectionStringLabel = "Key";
            break;
          case "com.shardsecure.filesystems.BoxStorage":
            this.type = "boxstorage";
            this.typeLabel = "Box Shard File System";
            this.showConnectionString = true;
            this.connectionStringLabel = "Public key";
            break;
          case "com.shardsecure.filesystems.GenericS3Storage":
            this.type = "generics3";
            this.showUserName = true;
            this.showPassword = true;
            this.showParam1 = true;
            this.showParam2 = true;
            this.showParam3switch = true;
            this.userNameLabel = "Access key ID";
            this.passwordLabel = "Secret access key";
            this.param1Label = "Region";
            this.param2Label = "Endpoint";
            this.param3Label = "Path Style";
            this.typeLabel = "Generic S3 Shard File System";
            //this.    compressionAlgo: "lz4",
            this.param3Items = [{id: "true", name: "On"}, {id: "false", name: "Off"}];
            break;
          case "com.shardsecure.filesystems.ALICloudStorage":
            this.type = "alicloud";
            this.showUserName = true;
            this.showPassword = true;
            this.showParam1 = false;
            this.showParam2 = true;
            this.showParam3 = false;
            this.userNameLabel = "Access key ID";
            this.passwordLabel = "Secret access key";
            this.param2Label = "Endpoint";
            this.typeLabel = "ALICloud Shard File System";
            break;
          default:
            this.type = "Unknown";
            break;
        }

      }
    },
    mounted() {
      for( var item in this.shardFileSystemItem){
        if( this[item] != null){
          this[item] = this.shardFileSystemItem[item];
        }
      }
      this.getShardFileSystemType();

    },
  }
</script>

<style scoped>

</style>