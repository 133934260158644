export function getConfigObject(uid, cfgData) {
  try {
    for (var item of cfgData["ShardFileSystems"]) {
      if (item["uid"] == uid) {
        return item;
      }
      for (var sf of item["Shardfiles"]) {
        if (sf["uid"] == uid) {
          return sf;
        }
      }
    }
    for (var policy of cfgData["Policies"]) {
      if (policy["uid"] == uid) {
        return policy;
      }
    }
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
  return {};
}

export function getRuleText(currRuleStr) {
  var currRule = getRuleSplitted(currRuleStr)
  if (currRule != null) {
    var ruleRead = currRule[0] + " ";
    if (currRule[0] == "size") {
      if (currRule[1] == ">") {
        ruleRead = ruleRead + "is greater than ";
      } else if (currRule[1] == "<") {
        ruleRead = ruleRead + "is less than ";
      } else {
        ruleRead = ruleRead + "is equal ";
      }
    } else {
      ruleRead = ruleRead + currRule[1] + " with ";
    }
    ruleRead = ruleRead + currRule[2];
    return ruleRead;
  }
  return "No rule set"
}

function getRuleSplitted(rule) {
  if (rule != null && rule.length > 0) {
    return rule.split("|");
  } else {
    return null;
  }
}